import * as React from "react";
import { useApplicationList } from "../../../../hooks/api/application/useApplicationList";
import { BreadCrumb, IPage } from "../../../atoms/BreadCrumb/BreadCrumb";
import { Loading } from "../../../atoms/Loading/Loading";
import { ApplicationsList } from "./ApplicationsList";
import { NewApplication } from "./NewApplication";

export const MyApplications: React.FC = () => {
    const applications = useApplicationList();
    const pages: Array<IPage> = [{ name: "Applications" }];

    if (applications.isLoading) {
        return <Loading />;
    }

    const hasApplications = (applications.data?.length ?? 0) > 0;
    const newApplicationTitle = hasApplications ? "New Application" : "No applications";
    const newApplicationDescription = hasApplications
        ? "Create a new DS160 application"
        : "Get started by creating a new DS160 application.";

    return (
        <>
            <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
                <BreadCrumb pages={pages} />
            </div>
            <ApplicationsList />
            <NewApplication title={newApplicationTitle} description={newApplicationDescription} />
        </>
    );
};
