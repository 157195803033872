import { PlusIcon } from "@heroicons/react/20/solid";
import { navigate } from "gatsby";
import * as React from "react";
import { useCallback } from "react";
import { useCreateApplication } from "../../../../hooks/api/application/useCreateApplication";
import { AppRoute } from "../../../../services/Constants/AppRoute";

interface IProps {
    readonly title: string;
    readonly description: string;
}

export const NewApplication: React.FC<IProps> = ({ title, description }) => {
    const onCreateApplicationSuccess = useCallback((response: { applicationId: string }) => {
        const applicationId = response.applicationId;
        navigate(AppRoute.EditApplication(applicationId));
    }, []);

    const createApplication = useCreateApplication(onCreateApplicationSuccess);

    const onNewApplicationClicked = React.useCallback(() => {
        createApplication.mutation.mutate();
    }, [createApplication.mutation]);
    return (
        <div className="text-center border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
            <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" aria-hidden="true" className="mx-auto size-12 text-gray-400">
                <path
                    d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                    strokeWidth={2}
                    vectorEffect="non-scaling-stroke"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
            <h3 className="mt-2 text-sm font-semibold text-gray-900">{title}</h3>
            <p className="mt-1 text-sm text-gray-500">{description}</p>
            <div className="mt-6">
                <button
                    type="button"
                    onClick={onNewApplicationClicked}
                    className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    <PlusIcon aria-hidden="true" className="-ml-0.5 mr-1.5 size-5" />
                    New Application
                </button>
            </div>
        </div>
    );
};
