import { ApplicationStatus } from "@ds160/library";
import { useQuery } from "@tanstack/react-query";
import axios, { AxiosRequestConfig } from "axios";
import { getAxiosRequestConfig } from "../../../functions/getAxiosRequestConfig";
import { Constants } from "../../../services/Constants/Constants";
import { useAuth } from "../../useAuth";
import { QueryCacheKey } from "../QueryCacheKey";

export interface IApplication {
    readonly _id: string;
    readonly projectName: string;
    readonly status: ApplicationStatus;
    readonly created: string;
    readonly revision: number;
}

interface IResponse {
    readonly applications: Array<IApplication>;
}

const getApplications = async (config: AxiosRequestConfig) => {
    const uri = `${Constants.ApiRoot}/application/list`;
    const { data } = await axios.get<IResponse>(uri, config);
    return data.applications;
};

export const useApplicationList = () => {
    const { authToken } = useAuth();
    const config = getAxiosRequestConfig(authToken);
    return useQuery([QueryCacheKey.Applications], () => getApplications(config));
};
